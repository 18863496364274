import React, { useState, useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Login from "./Login2";//Temp

export default function Home() {
    const [email,setEmail] = useState("");
    const setLogin = (email) =>{
	    setEmail(email);
	}
  return (
    <>
	  <Container>
		<Row className="justify-content-md-center">
		<Col md={3}></Col>
		<Col xs={12} md={6}>
		    <Card className="maincard">
		      <Card.Body>
			      <Login setEmail={(email)=>{setLogin(email)}}/>
		      </Card.Body>
		    </Card>
		</Col>
		<Col md={3}></Col>
		</Row>
      </Container>
	</>
  )
}
