import { useState } from 'react';

import { Routes, Route, Link, BrowserRouter } from 'react-router-dom';
import Home from './Page/Home2';
import About from './Page/About';
import Menu from './Components/Menu';
import Contact from './Page/Contact';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/payment" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
