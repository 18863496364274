import React from 'react'
import Button from 'react-bootstrap/Button';
import { GoogleOAuthProvider, useGoogleLogin,GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

const clientID = "533049298663-prumol2d1gll68nub56h084b2557aj5n.apps.googleusercontent.com";

/***
const GoogleAuthComponentLogin = (props) =>{
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {console.log("codeResponse=",codeResponse);
	    const decoded = jwtDecode(codeResponse.credential);
	    console.log("decoded=",decoded);
	    localStorage.setItem('email', decoded.email);
	    props.setEmail(decoded.email);
    }
  });
	  return (
	    <Button color="primary" onClick={login}>
	      Google認証
	    </Button>
	  );
}
***/
const Login = (props) => {
  // 認証用のProviderにGCPのクライアントIDを設定
  return (
    <GoogleOAuthProvider clientId={clientID}>
		<GoogleLogin
		  onSuccess={credentialResponse => {
		    console.log(credentialResponse);
		  }}
		  onError={() => {
		    console.log('Login Failed');
		  }}
		  useOneTap
		/>
    </GoogleOAuthProvider>
  );
}
/***
    <GoogleOAuthProvider clientId={clientID}>
      <GoogleAuthComponentLogin setEmail={(email)=>{props.setEmail(email);}}/>
    </GoogleOAuthProvider>
***/
export default Login;
